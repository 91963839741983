Vue.component('genericReportPage',{
    computed:{
        reportName:function(){
            return this.$route.params.reportName;
        }
    },
    template:`<div>            
                  <resetPasswordComponent></resetPasswordComponent>
                  <headerSectionComponent></headerSectionComponent>
                  <registerComponent></registerComponent>
                  <loginComponent></loginComponent>
                  <template v-if="$store.getters.getAppState=='ready'">
                    <div class="section white">
                        <div class="container">
                            <div class="row">
                                <div class="left sticky-component home-category-tree">
                                    <homeCategorySectionComponent :only_icons_view=true></homeCategorySectionComponent>
                                </div>
                                <div class="col m9 s12" >
                                     <reportComponent :reportName="reportName" :key="'report-page'+reportName">
                                        <template slot="parameters" scope="reportScope">
                                            <genericReportForm :reportScope="reportScope" :reportName="$route.params.reportName"></genericReportForm>
                                        </template>
                                     </reportComponent>
                                </div>
                                <div class="col m2 s12 sticky-component right">
                                    <userSectionComponent></userSectionComponent>
                                    <carComponent></carComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="container center section white" style="min-height: 60%;" >
                       <h3>{{tr('Page loading please wait')}}</h3>
                     </div>
                  </template>
                  <footerSectionComponent></footerSectionComponent>
              </div>`
})